/**
 * @author Gowtham Prasath
 * @description OTP Viewing Dialog Box
 * @copyright Bookwater tech pvt ltd
 */
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import DialogContent from "@mui/material/DialogContent";
import { Box, Button, DialogActions, Link, Typography } from "@mui/material";
import { dialogStyles } from "./orderDetailsStyles";
import appColors from "../../../../Assets/AppTheme/appColors";
import { useDispatch, useSelector } from "react-redux";
import closeIcon from "../../../../Assets/newMobileIcon/Booking/CloseIcon.svg";
import * as MetaFunctions from "../../../../Components/CommonComponents/MetaFunctions";
import DownloadInvoiceButton from "../../../Buttons/DownloadInvoiceButton";
import SendInvoice from "../../../Buttons/SendEmailButton";
import EmailVericationDialog from "../../PdwOrderDetails/EmailVerificationDialog";
import ShareIcon from "@mui/icons-material/Share";
import { toast } from "react-toastify";
import {
  DownloadInvoice,
  GetInvoice,
} from "../../../../Redux/actions/ordersAction";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { ClearPendingOrderData } from "../../../../Redux/actions/checkOutAction";
import appTheme from "../../../../Assets/AppTheme/appTheme";
import { SelectedProductaction } from "../../../../Redux/actions/skuBookingAction";
import CustomConsole from "../../../../coustomConsole";
import { handleClickPhoneCall } from "../../../../Pages/Support/Support";
import CallIcon from "@mui/icons-material/Call";
import {
  startLoading,
  stopLoading,
} from "../../../../Redux/actions/spinnerActions";
const deliveryStatus = {
  100: "Scheduled",
  200: "Delivering",
  300: "Delivered",
  400: "Cancelled",
};
const evacuateStatus = {
  100: "Scheduled",
  200: "Evacuating",
  300: "Evacuated",
  400: "Cancelled",
};
const orderTerm = {
  0: "Single",
  100: "Weekly",
  200: "Fortnightly",
  300: "Monthly",
};
const orderStatus = {
  100: "Not Confirmed",
  200: "Confirmed",
  300: "Rejected",
  400: "Cancelled",
  500: "Delivered",
  999: "Completed",
};
const contentStyles = {
  contentHeading: {
    fontSize: appTheme.fontSizeMedium,
    color: appColors.headerTextColor,
  },
  Heading: {
    fontSize: appTheme.fontSizeLarge,
    color: appColors.headerTextColor,
    fontWeight: "bold",
  },
  contentSubHeading: {
    fontSize: appTheme.fontSizeSmall,
    color: appColors.darkGray,
    fontWeight: "500",
    // your existing styles here
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  topBarContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    padding: "0.5rem",
    color: appColors.headerTextColor,
  },
  addressBox: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderBottom: "2px solid gray",
    padding: ".5rem .5rem .5rem .3rem",
  },
  deliveryStatusBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: ".3rem",
  },
  cancelButton: {
    backgroundColor: appColors.Failed,
    borderRadius: "0", // Set the border radius to 0 (no rounding)
    borderRadius: ".5rem",
    height: "25px",
    "&: hover": {
      backgroundColor: appColors.Failed,
    },
  },
  payButton: {
    backgroundColor: appColors.blue,
    borderRadius: "0", // Set the border radius to 0 (no rounding)
    borderRadius: ".5rem",
    height: "25px",
    "&: hover": {
      backgroundColor: appColors.blue,
    },
  },
  labelText: {
    whiteSpace: "nowrap",
    transform: "rotate(90deg)",
    fontSize: appTheme.fontSizeSmall,
    fontWeight: "bold",
    marginTop: ".5rem",
    color: "#152057",
    marginRight: ".5rem",
  },
};
export default function OrderDetailsDialog(props) {
  const dispatch = useDispatch();
  const histroy = useHistory();
  const orderDetails = useSelector((state) => state.ordersDetails.singleOrder);
  const invoiceDetails = useSelector((state) => state.ordersDetails);
  const profile = useSelector((state) => state.userProfile);
  const [productId, setProductId] = React.useState(0);
  const [isEmailVerified, setIsEmailVerified] = React.useState(false);
  const [twentyLiterFlag, setTwentyLiterFlag] = React.useState(false);
  const [openInvoice, setOpenInvoice] = React.useState(false);
  const [emailInvoiceClicked, setEmailInvoiceClicked] = React.useState(false);
  const finalPrice = Number(orderDetails?.final_price) || 0;
  const discount = Number(orderDetails?.discount) || 0;
  const [deliveryCharges, setDeliveryCharges] = React.useState(0);
  const totalPrice = finalPrice + discount;
  const formattedTotal = totalPrice.toFixed(2);

  //function for handling selected product info
  const handelSelectedProduct = (data) => {
    CustomConsole(data);
    if (data === productId) {
      setProductId(0);
    } else {
      setProductId(data);
    }
  };
  //function for closing dialog box
  const handleClose = () => {
    if (props.pageName === null) {
      props.setOpenOrderDetailsDialog(false);
    } else if (props.pageName && window.innerWidth <= 600) {
      histroy.push("/mobile");
    } else if (props.pageName && window.innerWidth > 600) {
      histroy.push("/dashboard/booknow");
    } else {
      props.setOpenOrderDetailsDialog(false);
    }
    dispatch(ClearPendingOrderData());
    //for fixing checkout button showing in booking
    dispatch(SelectedProductaction({}));
  };

  //useEffect for setting profile details
  React.useEffect(() => {
    CustomConsole(profile);
    setIsEmailVerified(profile.user.is_email_verified);
  }, [profile]);
  //function for color setting
  const getOrderStatusColor = (orderStatus, tankerType) => {
    if (orderStatus === 400) {
      return appColors.Failed;
    } else if (orderStatus === 200) {
      return "orange";
    } else if (orderStatus === 500) {
      return "green";
    } else {
      return appColors.darkBlue;
    }
  };

  //function for payment  setting
  const getPaymentStatusColor = (Status, tankerType) => {
    if (Status === 400) {
      return appColors.Failed;
    } else if (Status === 100) {
      return "orange";
    } else if (Status === 0) {
      return "#00FF00";
    } else {
      return appColors.Failed;
    }
  };
  const statusColor = getOrderStatusColor(
    orderDetails?.order_status,
    orderDetails?.tanker_type
  );
  const paymentColor = getPaymentStatusColor(orderDetails?.payment_status);

  // function to handle invoice download
  const handleDownloadInvoice = async () => {
    // Start the loading before dispatching
    dispatch(startLoading());
    try {
      // Dispatch the DownloadInvoice action and wait for completion
      await dispatch(DownloadInvoice(orderDetails?.order_id));
    } catch (error) {
      // Handle any errors
      console.error("Error downloading invoice:", error);
    } finally {
      // Stop the loading indicator no matter what happens
      dispatch(stopLoading());
      setOpenInvoice(false);
      props.setOpenOrderDetailsDialog(false);
    }
  };

  //function for mail invoice
  const handleSendInvoice = () => {
    CustomConsole(`isEmailVerified ${isEmailVerified}`);
    if (isEmailVerified === false) {
      setEmailInvoiceClicked(true);
    } else {
      dispatch(GetInvoice(orderDetails?.order_id));
    }
    setOpenInvoice(false);
  };

  //useEffect for Toast msgs
  React.useEffect(() => {
    CustomConsole(invoiceDetails);
    if (invoiceDetails.invoice_error === true) {
      if (invoiceDetails?.invoice_message?.length) {
        toast.dark(invoiceDetails.invoice_message, {
          position: "bottom-right",
          autoClose: 5000,
          type: "error",
        });
      }
    } else {
      if (invoiceDetails?.invoice_message?.length) {
        toast.dark(invoiceDetails.invoice_message, {
          position: "bottom-right",
          autoClose: 5000,
          type: "success",
        });
      }
    }
    invoiceDetails.invoice_message = "";
  }, [invoiceDetails]);

  //function for edit order dialog open
  const handelOpenEditOrder = (data, type, orderId) => {
    CustomConsole(data);
    props.handelOpenEditOrderDetails(data, type, orderId);
  };

  //useEffect to calculate delivercahrge sum price
  React.useEffect(() => {
    //function for getting selected quantity
    const ExtraCharges = orderDetails?.products?.reduce((sum, product) => {
      if (product.extra_charges.price !== undefined) {
        sum += product.extra_charges.price;
      }
      return sum;
    }, 0);
    setDeliveryCharges(ExtraCharges);
  }, [orderDetails?.products]);

  //function to close invoice dialog
  const handelCloseInvoice = () => {
    setOpenInvoice(false);
  };

  //function to handel phone click on supplier
  const handelPhoneClickSupplier = () => {
    handleClickPhoneCall(orderDetails?.driver_phone);
    handleClose();
  };

  return (
    <>
      {Object.keys(orderDetails).length > 0 && (
        <Dialog
          open={props.openOrderDetailsDialog}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          sx={dialogStyles.dialog}
          PaperProps={{
            sx: {
              borderRadius: "20px", // Set border radius for curved effect
            },
          }}
        >
          <DialogContent sx={dialogStyles.dialogContent}>
            <Box sx={dialogStyles.orderIdStyle}>
              <Box
                sx={{
                  top: 0,
                  bgcolor: appColors.cardColor,
                  // boxShadow: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  alignContent: "center",
                  fontWeight: "bold",
                }}
              >
                <Box sx={contentStyles.topBarContent}>
                  &nbsp; &nbsp;
                  <span>{orderDetails?.order_id}</span>
                </Box>
                {/* {orderDetails?.packaged_drinking_water?.twentyltr_capacity_qty >
                  0 && ( */}
                <Box>
                  <Typography
                    sx={contentStyles.labelText}
                    onClick={() => props.shareContent(orderDetails)}
                  >
                    <ShareIcon
                      style={{
                        color: appColors.textColorNew,
                        transform: "rotate(270deg)",
                      }}
                    />
                  </Typography>
                </Box>
                {/* )} */}
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  minWidth: "300px",
                  padding: "0 1rem",
                }}
              >
                <Box sx={contentStyles.addressBox}>
                  <span style={contentStyles.Heading}>Delivery Address</span>
                  <span style={contentStyles.contentSubHeading}>
                    {orderDetails?.delivery_address?.address_type === ""
                      ? "Home"
                      : orderDetails?.delivery_address?.address_type}
                    ,{orderDetails?.delivery_address?.contact_name},
                    {orderDetails?.delivery_address?.address_line1},
                    {orderDetails?.delivery_address?.address_line2},
                  </span>
                  <Typography sx={contentStyles.contentSubHeading}>
                    {orderDetails?.delivery_address?.address_line1}
                  </Typography>
                  <Typography sx={contentStyles.contentSubHeading}>
                    {orderDetails?.delivery_address?.address_line2}
                  </Typography>
                  <Typography sx={contentStyles.contentSubHeading}>
                    {orderDetails?.delivery_address?.pincode},
                    {orderDetails?.delivery_address?.service_area}
                  </Typography>
                </Box>
                <Box sx={contentStyles.deliveryStatusBox}>
                  <span style={contentStyles.contentHeading}>
                    Delivery status
                  </span>
                  <span
                    style={{
                      fontSize: ".8rem",
                      color: statusColor,
                      border: "2px solid",
                      borderRadius: "5px",
                      padding: ".1rem 1rem",
                    }}
                  >
                    {" "}
                    {MetaFunctions.orderStatusFun(
                      orderDetails?.order_status,
                      orderDetails?.tanker_type
                    )}
                  </span>
                </Box>
                {orderDetails?.order_status != 400 && (
                  <Box sx={contentStyles.deliveryStatusBox}>
                    <span style={contentStyles.contentHeading}>
                      Delivery Date
                    </span>
                    <span
                      style={{
                        fontSize: ".8rem",
                        color: appColors.darkGray,

                        borderRadius: "5px",
                        padding: ".1rem",
                      }}
                    >
                      {" "}
                      {orderDetails?.delivery_date}
                    </span>
                  </Box>
                )}
                {orderDetails?.order_status == 200 && (
                  <Box sx={contentStyles.deliveryStatusBox}>
                    <span style={contentStyles.contentHeading}>
                      Delivery OTP
                    </span>
                    <span
                      style={{
                        fontSize: ".8rem",
                        color: appColors.darkGray,

                        borderRadius: "5px",
                        padding: ".1rem",
                      }}
                    >
                      {" "}
                      {orderDetails?.delivery_otp_type === ""
                        ? orderDetails?.delivery_otp
                        : "No OTP"}
                    </span>
                  </Box>
                )}
                <Box sx={[contentStyles.deliveryStatusBox]}>
                  <span style={contentStyles.contentHeading}>
                    Delivery expected by
                  </span>
                  <span style={contentStyles.contentSubHeading}>
                    {orderDetails?.packaged_drinking_water.delivery_slots !==
                      undefined &&
                    orderDetails?.packaged_drinking_water.delivery_slots !==
                      null
                      ? orderDetails?.packaged_drinking_water.delivery_slots
                          .admin_selected_delivery_slot !== undefined &&
                        orderDetails?.packaged_drinking_water.delivery_slots
                          .admin_selected_delivery_slot !== ""
                        ? orderDetails?.packaged_drinking_water.delivery_slots
                            .admin_selected_delivery_slot
                        : orderDetails?.packaged_drinking_water.delivery_slots
                            .supplier_selected_delivery_slot !== undefined &&
                          orderDetails?.packaged_drinking_water.delivery_slots
                            .supplier_selected_delivery_slot !== ""
                        ? orderDetails?.packaged_drinking_water.delivery_slots
                            .supplier_selected_delivery_slot
                        : "-"
                      : "-"}
                  </span>
                </Box>

                <Box
                  sx={[
                    contentStyles.deliveryStatusBox,
                    { borderBottom: "2px solid gray" },
                  ]}
                >
                  {orderDetails?.driver_phone !== "" &&
                    orderDetails?.order_status === 200 && (
                      <>
                        <span style={contentStyles.contentHeading}>
                          Call Delivery Agent
                        </span>
                        <span
                          style={contentStyles.contentSubHeading}
                          onClick={handelPhoneClickSupplier}
                        >
                          <CallIcon />
                        </span>
                      </>
                    )}
                </Box>
                <Box sx={[contentStyles.Heading, { margin: ".3rem" }]}>
                  Order Details
                </Box>
                <Box>
                  <Box sx={[{ width: "100%" }]}>
                    {orderDetails?.packaged_drinking_water
                      ?.twentyltr_capacity_qty > 0 && (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "space-between",
                          marginBottom: ".3rem",
                          padding: "0 0 0 .5rem ",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography
                            sx={{
                              color: appColors.headerTextColor,
                              fontSize: ".9rem",
                            }}
                          >
                            {orderDetails?.packaged_drinking_water
                              ?.twentyltr_capacity_qty * 1}{" "}
                            x{" "}
                            {orderDetails?.packaged_drinking_water?.name ===
                            undefined
                              ? orderDetails?.packaged_drinking_water
                                  ?.selected_container_capacity
                              : orderDetails?.packaged_drinking_water
                                  ?.name}{" "}
                            {orderDetails?.order_status === 100 ||
                            orderDetails?.order_status === 200 ? (
                              <Link
                                sx={{
                                  fontSize: ".8rem",
                                  cursor: "pointer",
                                  color: appColors.Failed,
                                }}
                                onClick={() =>
                                  handelOpenEditOrder(
                                    orderDetails?.packaged_drinking_water,
                                    "20Liters",
                                    orderDetails?.order_id
                                  )
                                }
                              >
                                (Edit)
                              </Link>
                            ) : null}
                          </Typography>
                          <Box
                            sx={{ display: "flex", flexDirection: "row" }}
                            onClick={() => setTwentyLiterFlag(!twentyLiterFlag)}
                          >
                            <Typography
                              sx={{
                                color: appColors.darkGray,
                                fontWeight: "500",
                                fontSize: ".9rem",
                              }}
                            >
                              ₹
                              {orderDetails?.packaged_drinking_water
                                ?.final_price === undefined
                                ? handelFinalAmountAndDamagedCanPrice(
                                    orderDetails,
                                    orderDetails?.final_price,
                                    orderDetails?.packaged_drinking_water
                                  ).price
                                : handelFinalAmountAndDamagedCanPrice(
                                    orderDetails,
                                    orderDetails?.packaged_drinking_water
                                      ?.final_price,
                                    orderDetails?.packaged_drinking_water
                                  ).price}
                            </Typography>
                            <span
                              style={{
                                color: appColors.darkGray,
                                fontSize: ".8rem",
                              }}
                            >
                              {twentyLiterFlag ? (
                                <KeyboardArrowUpIcon
                                  sx={{ fontSize: "20px" }}
                                />
                              ) : (
                                <KeyboardArrowDownIcon
                                  sx={{ fontSize: "20px" }}
                                />
                              )}
                            </span>
                          </Box>
                        </Box>
                        {twentyLiterFlag && (
                          <>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: appColors.headerTextColor,
                                  fontSize: ".9rem",
                                }}
                              >
                                Product Price(
                                {
                                  orderDetails?.packaged_drinking_water
                                    ?.twentyltr_capacity_qty
                                }
                                )
                              </Typography>
                              <Typography
                                sx={{
                                  color: appColors.darkGray,
                                  fontWeight: "500",
                                  fontSize: ".9rem",
                                }}
                              >
                                ₹
                                {orderDetails?.packaged_drinking_water
                                  ?.sub_total_price !== undefined &&
                                orderDetails?.packaged_drinking_water
                                  ?.sub_total_price !== ""
                                  ? orderDetails?.packaged_drinking_water
                                      ?.sub_total_price
                                  : orderDetails?.packaged_drinking_water
                                      ?.twenty_ltr_capacity_amt}
                              </Typography>
                            </Box>
                            {orderDetails?.packaged_drinking_water
                              ?.booking_type !== 2 && (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  justifyContent: "space-between",
                                  fontSize: ".9rem",
                                }}
                              >
                                <Typography
                                  sx={{
                                    color: appColors.headerTextColor,
                                    fontSize: ".9rem",
                                  }}
                                >
                                  Total Security Deposit (
                                  {
                                    orderDetails?.packaged_drinking_water
                                      ?.deposit?.quantity
                                  }
                                  )
                                </Typography>
                                <Typography
                                  sx={{
                                    color: appColors.darkGray,
                                    fontWeight: "500",
                                    fontSize: ".9rem",
                                  }}
                                >
                                  ₹
                                  {
                                    orderDetails?.packaged_drinking_water
                                      ?.deposit?.price
                                  }
                                </Typography>
                              </Box>
                            )}
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: appColors.headerTextColor,
                                  fontSize: ".9rem",
                                  fontSize: ".9rem",
                                }}
                              >
                                Delivery Charges
                              </Typography>
                              <Typography
                                sx={{
                                  color: appColors.darkGray,
                                  fontWeight: "500",
                                  fontSize: ".9rem",
                                }}
                              >
                                ₹
                                {
                                  orderDetails?.packaged_drinking_water
                                    ?.extra_charges?.price
                                }
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: appColors.headerTextColor,
                                  fontSize: ".9rem",
                                }}
                              >
                                Taxes
                              </Typography>
                              <Typography
                                sx={{
                                  color: appColors.darkGray,
                                  fontWeight: "500",
                                  fontSize: ".9rem",
                                }}
                              >
                                ₹
                                {
                                  orderDetails?.packaged_drinking_water
                                    ?.total_gst?.charges
                                }
                              </Typography>
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: appColors.headerTextColor,
                                  fontSize: ".9rem",
                                }}
                              >
                                Damage Charges (
                                {
                                  handelFinalAmountAndDamagedCanPrice(
                                    orderDetails,
                                    0,
                                    orderDetails?.packaged_drinking_water
                                  ).quantity
                                }
                                )
                              </Typography>
                              <Typography
                                sx={{
                                  color: appColors.darkGray,
                                  fontWeight: "500",
                                  fontSize: ".9rem",
                                }}
                              >
                                ₹
                                {
                                  handelFinalAmountAndDamagedCanPrice(
                                    orderDetails,
                                    0,
                                    orderDetails?.packaged_drinking_water
                                  ).unitPrice
                                }
                              </Typography>
                            </Box>
                          </>
                        )}
                      </Box>
                    )}
                  </Box>
                </Box>
                <Box sx={[{ width: "100%" }]}>
                  {orderDetails?.products?.map((data) => (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        marginBottom: ".3rem",
                        padding: "0 0 0 .5rem ",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                        key={data.product_id}
                      >
                        <Typography
                          sx={{
                            color: appColors.headerTextColor,
                            fontSize: ".9rem",
                          }}
                        >
                          {data.quantity} x{" "}
                          {data.name === undefined
                            ? data?.capacity
                            : data?.name}{" "}
                          {orderDetails?.order_status === 100 ||
                          orderDetails?.order_status === 200 ? (
                            <Link
                              sx={{
                                fontSize: ".8rem",
                                cursor: "pointer",
                                color: appColors.Failed,
                              }}
                              onClick={() =>
                                handelOpenEditOrder(
                                  data,
                                  "sku",
                                  orderDetails?.order_id
                                )
                              }
                            >
                              (Edit)
                            </Link>
                          ) : null}
                        </Typography>
                        <Box
                          sx={{ display: "flex", flexDirection: "row" }}
                          onClick={() => handelSelectedProduct(data.name)}
                        >
                          <Typography
                            sx={{
                              color: appColors.darkGray,
                              fontWeight: "500",
                              fontSize: ".9rem",
                            }}
                          >
                            {/* &nbsp; ₹{data.final_price} */}₹
                            {
                              handelDamageCanPriceOtherProducts(
                                orderDetails,
                                data
                              ).price
                            }
                          </Typography>
                          <span
                            style={{
                              color: appColors.darkGray,
                              fontSize: ".8rem",
                            }}
                          >
                            {productId === data.name ? (
                              <KeyboardArrowUpIcon sx={{ fontSize: "20px" }} />
                            ) : (
                              <KeyboardArrowDownIcon
                                sx={{ fontSize: "20px" }}
                              />
                            )}
                          </span>
                        </Box>
                      </Box>
                      {data.name === productId && (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              sx={{
                                color: appColors.headerTextColor,
                                fontSize: ".9rem",
                              }}
                            >
                              Product Price({data?.quantity * data?.sku_qty})
                            </Typography>
                            <Typography
                              sx={{
                                color: appColors.darkGray,
                                fontWeight: "500",
                                fontSize: ".9rem",
                              }}
                            >
                              &nbsp; ₹
                              {data?.sub_total_price !== undefined &&
                              data?.sub_total_price !== ""
                                ? data?.sub_total_price
                                : data?.product_price}
                            </Typography>
                          </Box>
                          {data?.booking_type !== 2 && (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                fontSize: ".9rem",
                              }}
                            >
                              <Typography
                                sx={{
                                  color: appColors.headerTextColor,
                                  fontSize: ".9rem",
                                }}
                              >
                                Total Security Deposit (
                                {data?.deposit?.quantity})
                              </Typography>
                              <Typography
                                sx={{
                                  color: appColors.darkGray,
                                  fontWeight: "500",
                                  fontSize: ".9rem",
                                }}
                              >
                                &nbsp; ₹{data?.deposit?.price}
                              </Typography>
                            </Box>
                          )}
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              sx={{
                                color: appColors.headerTextColor,
                                fontSize: ".9rem",
                              }}
                            >
                              Delivery Charges
                            </Typography>
                            <Typography
                              sx={{
                                color: appColors.darkGray,
                                fontWeight: "500",
                              }}
                            >
                              &nbsp; ₹{data?.extra_charges?.price}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              sx={{
                                color: appColors.headerTextColor,
                                fontSize: ".9rem",
                              }}
                            >
                              Taxes
                            </Typography>
                            <Typography
                              sx={{
                                color: appColors.darkGray,
                                fontWeight: "500",
                                fontSize: ".9rem",
                              }}
                            >
                              &nbsp; ₹{data?.total_gst?.charges}
                            </Typography>
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography
                              sx={{
                                color: appColors.headerTextColor,
                                fontSize: ".9rem",
                              }}
                            >
                              Damage Charges (
                              {
                                handelDamageCanPriceOtherProducts(
                                  orderDetails,
                                  data
                                ).quantity
                              }
                              )
                            </Typography>
                            <Typography
                              sx={{
                                color: appColors.darkGray,
                                fontWeight: "500",
                                fontSize: ".9rem",
                              }}
                            >
                              ₹
                              {
                                handelDamageCanPriceOtherProducts(
                                  orderDetails,
                                  data
                                ).unitPrice
                              }
                            </Typography>
                          </Box>
                        </>
                      )}
                    </Box>
                  ))}
                  <Box sx={[contentStyles.deliveryStatusBox]}>
                    <span style={contentStyles.contentHeading}>Order Type</span>
                    <span style={contentStyles.contentSubHeading}>
                      {(orderDetails?.products &&
                        orderDetails.products.length > 0 &&
                        orderDetails.products[0].booking_type !== undefined &&
                        orderDetails.products[0].booking_type === 2) ||
                      (orderDetails?.packaged_drinking_water?.booking_type !==
                        undefined &&
                        orderDetails?.packaged_drinking_water?.booking_type ===
                          2)
                        ? "One-Time Purchase"
                        : "Refill Purchase"}
                    </span>
                  </Box>
                  <Box sx={contentStyles.deliveryStatusBox}>
                    <span style={contentStyles.contentHeading}>
                      Total Delivery Charge
                    </span>
                    <span style={contentStyles.contentSubHeading}>
                      ₹
                      {orderDetails?.packaged_drinking_water
                        ?.total_delivery_charges + deliveryCharges}
                    </span>
                  </Box>
                  {/* <Box sx={contentStyles.deliveryStatusBox}>
                    <span style={contentStyles.contentHeading}>
                      Order Total
                    </span>
                    <span style={contentStyles.contentSubHeading}>
                      ₹{formattedTotal}
                    </span>
                  </Box> */}
                  <Box sx={contentStyles.deliveryStatusBox}>
                    <span style={contentStyles.contentHeading}>
                      Discount(s) Applied
                    </span>
                    <span style={contentStyles.contentSubHeading}>
                      {" "}
                      - ₹{orderDetails?.discount}
                    </span>
                  </Box>

                  <Box
                    sx={[
                      contentStyles.deliveryStatusBox,
                      { borderBottom: "2px solid gray" },
                    ]}
                  >
                    <span style={contentStyles.contentHeading}>
                      Grand Total
                    </span>
                    <span style={contentStyles.contentSubHeading}>
                      ₹{orderDetails?.final_price}
                    </span>
                  </Box>
                </Box>
                <Box
                  sx={[
                    contentStyles.deliveryStatusBox,
                    { borderBottom: "2px solid gray" },
                  ]}
                >
                  <span style={contentStyles.contentHeading}>
                    Payment Status
                  </span>
                  <span
                    style={{
                      fontSize: ".8rem",
                      color: appColors.darkGray,
                      border: `2px solid ${paymentColor}`,
                      borderRadius: "5px",
                      padding: ".1rem 1rem",
                    }}
                  >
                    {" "}
                    {orderDetails?.payment_status === 100
                      ? "PENDING"
                      : orderDetails?.payment_status === 0
                      ? "PAID"
                      : orderDetails?.payment_status === 400
                      ? "FAILED"
                      : "MISMATCH"}
                  </span>
                </Box>
                <Box sx={[contentStyles.deliveryStatusBox]}>
                  <span style={contentStyles.contentHeading}>
                    Paid Using&nbsp;&nbsp;
                  </span>
                  <span
                    style={{
                      fontSize: ".8rem",
                      color: appColors.darkGray,
                    }}
                  >
                    {" "}
                    {orderDetails?.payment_method === "PAYSHARP"
                      ? "UPI"
                      : orderDetails?.payment_method}
                  </span>
                </Box>
              </Box>
            </Box>
          </DialogContent>
          <DialogActions
            sx={{
              display: "flex",
              justifyContent: "space-around",
              background: appColors.cardColor,
            }}
          >
            {orderDetails?.order_status !== 100 &&
              orderDetails?.order_status !== 200 &&
              orderDetails?.order_status === 500 &&
              orderDetails?.payment_type !== 300 && (
                <Button
                  variant="contained"
                  sx={[contentStyles.payButton, { fontSize: ".7rem" }]}
                  size="small"
                  onClick={() => setOpenInvoice(true)}
                >
                  Invoice
                </Button>
              )}
            {orderDetails?.order_status === 200 ||
            orderDetails?.order_status === 400 ||
            orderDetails?.order_status === 500 ? (
              <>
                <Button
                  variant="contained"
                  sx={contentStyles.payButton}
                  size="small"
                  // disabled={props.pendingFlag}
                  onClick={() =>
                    props.handelReorderData(orderDetails, orderDetails.order_id)
                  }
                >
                  <span style={{ fontSize: ".7rem" }}>Repeat</span>
                </Button>
              </>
            ) : (
              ""
            )}
            {orderDetails.payment_type !== 300 &&
            orderDetails.order_status === 100 ? (
              <Button
                variant="contained"
                sx={contentStyles.payButton}
                size="small"
                onClick={() => props.handelPaymentFlag(orderDetails)}
              >
                <span style={{ fontSize: ".7rem" }}>Pay</span>
              </Button>
            ) : (
              ""
            )}{" "}
            <Button
              variant="contained"
              sx={[contentStyles.payButton, { backgroundColor: "#152057" }]}
              size="small"
              onClick={() => handleClose()}
            >
              <span style={{ fontSize: ".7rem" }}>close</span>
            </Button>
            {orderDetails?.order_status === 200 ||
            orderDetails?.order_status === 100 ? (
              <>
                <Button
                  variant="contained"
                  sx={contentStyles.cancelButton}
                  size="small"
                  onClick={() => props.handleCancelOrder(orderDetails)}
                >
                  <span style={{ fontSize: ".7rem" }}>Cancel</span>
                </Button>
              </>
            ) : (
              ""
            )}
          </DialogActions>
          <EmailVericationDialog
            emailInvoiceClicked={emailInvoiceClicked}
            setEmailInvoiceClicked={setEmailInvoiceClicked}
            setIsEmailVerified={setIsEmailVerified}
          />

          <Dialog
            open={openInvoice}
            onclose={handelCloseInvoice}
            PaperProps={{
              sx: {
                borderRadius: "20px", // Set border radius for curved effect
              },
            }}
          >
            <DialogContent
              sx={{ bgcolor: appColors.secondaryCard, position: "relative" }}
            >
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography
                  sx={{ color: appColors.headerTextColor, fontSize: ".9rem" }}
                >
                  Do You want to receive your invoice for{" "}
                  {orderDetails?.order_id}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    marginTop: "1rem",
                  }}
                >
                  <DownloadInvoiceButton
                    handleDownloadInvoice={handleDownloadInvoice}
                  />
                  <SendInvoice handleSendInvoice={handleSendInvoice} />
                </Box>
              </Box>
            </DialogContent>
            <img
              alt=""
              src={closeIcon}
              style={{
                position: "absolute",
                right: 0,
                top: 0,
                cursor: "pointer",
                height: "30px",
              }}
              onClick={handelCloseInvoice}
            />
          </Dialog>
        </Dialog>
      )}
    </>
  );
}

//function to add final amount and damaged 20_liter can price
export const handelFinalAmountAndDamagedCanPrice = (
  orderDetails,
  amount,
  product
) => {
  if (orderDetails?.packaged_drinking_water?.damage_invoice?.length > 0) {
    const filterAmount =
      orderDetails?.packaged_drinking_water?.damage_invoice.find((data) => {
        return (
          data?.capacity === product?.selected_container_capacity &&
          data?.material === product?.selected_container_material
        );
      });
    console.log("filterAmount");
    console.log(filterAmount);
    return {
      price: amount + filterAmount?.product_price,
      quantity: filterAmount?.quantity,
      unitPrice: filterAmount?.product_price,
    };
  } else {
    return {
      price: amount,
      quantity: 0,
      unitPrice: 0,
    };
  }
};

//function to add final amount and damaged can price for other products
export const handelDamageCanPriceOtherProducts = (orderDetails, product) => {
  if (orderDetails?.packaged_drinking_water?.damage_invoice?.length > 0) {
    const filterAmount =
      orderDetails?.packaged_drinking_water?.damage_invoice.find((data) => {
        return (
          data?.capacity === product?.capacity &&
          data?.material === product?.material
        );
      });
    return {
      price: product?.final_price + filterAmount?.product_price,
      quantity: filterAmount?.quantity,
      unitPrice: filterAmount?.product_price,
    };
  } else {
    return {
      price: product?.final_price,
      quantity: 0,
      unitPrice: 0,
    };
  }
};
