/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */
import React from "react";
import "./giftCards.css";
import GiftBOX from "../../../Assets/newMobileIcon/menu/giftBox.svg";
export default function AvailableGiftCardList({ openDialog, setOpenDialog }) {
  //function to handel open dialog
  const handelOpenDialog = () => {
    setOpenDialog(true);
  };
  return (
    <>
      <div class="discount-card">
        <div class="content">
          <p class="main-text">20% Discount next orders</p>
          <p class="sub-text">single treat</p>
          <div class="addToCart">
            <p class="validity">Valid until next order</p>
            <div class="addButton">
              <p class="price">₹200</p>&nbsp;|&nbsp;
              <a  class="bn3637 bn38" onClick={handelOpenDialog}>
                Add
              </a>
            </div>
          </div>
        </div>
        <div class="gift-icon">
          <img src={GiftBOX} alt="Bookwater" />
        </div>
      </div>
    </>
  );
}
