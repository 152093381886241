/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */
import { Button, Grid, Typography } from "@mui/material";
import React from "react";
import AvailedGiftCardList from "../../Components/Cards/GiftCards/availedGiftCardsList";
import AvailableGiftCardList from "../../Components/Cards/GiftCards/availableGiftCards";
import appColors from "../../Assets/AppTheme/appColors";
import appTheme from "../../Assets/AppTheme/appTheme";
import GiftCardDetailsDialog from "../../Components/DialogBoxes/GiftCardDialog/giftCardDetails";

export default function AvailedGiftCards() {
  const [buyGistCardFlag, setBuyGiftCardFlag] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);

  //function to handel buy gift card action
  const handelClickBuyGiftCards = () => {
    setBuyGiftCardFlag(true);
  };
  //function to handel buy gift card back button
  const handelBackBuyGiftCards = () => {
    setBuyGiftCardFlag(false);
  };
  return (
    <>
      <Grid container gap={2}>
        <Grid item xs={12}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              style={{
                fontSize: appTheme.fontSizexl,
                color: appColors.darkGray,
                fontWeight: "bold",
              }}
            >
              Gift Cards
            </Typography>
            {!buyGistCardFlag && (
              <Button
                variant="contained"
                size="small"
                onClick={handelClickBuyGiftCards}
              >
                Buy Gift Cards
              </Button>
            )}
            {buyGistCardFlag && (
              <Button
                variant="contained"
                size="small"
                onClick={handelBackBuyGiftCards}
              >
                Back
              </Button>
            )}
          </div>
        </Grid>{" "}
        <Grid item xs={12}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              flexWrap: "wrap",
              gap:"1rem"
            }}
          >
            {!buyGistCardFlag && <AvailedGiftCardList />}
            {buyGistCardFlag && (
              <AvailableGiftCardList
                openDialog={openDialog}
                setOpenDialog={setOpenDialog}
              />
            )}
          </div>
        </Grid>
      </Grid>
      {openDialog && (
        <GiftCardDetailsDialog
          openDialog={openDialog}
          setOpenDialog={setOpenDialog}
        />
      )}
    </>
  );
}
