/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description Web View Booking Page
 *
 */

import { AppBar, Box, Grid, Typography } from "@mui/material";
import React from "react";
import BookingAddressCard from "../../Components/Cards/SkuCards/BookingAddressCard";
import BookingSelectDateCard from "../../Components/Cards/SkuCards/BookingSelectDate";
import BookingCardsSku from "../../Components/Cards/SkuCards/BookingCards";
import failedOrder from "../../Assets/newMobileIcon/Booking/Order Failed.svg";
import CheckOutCardSku from "../../Components/Cards/SkuCards/checkOutCard";
import SubscriptionSchedule from "../../Components/Cards/SkuCards/SubscriptionDateCard";
import IconListManager from "../../Assets/AppTheme/appIcons";
import { useDispatch, useSelector } from "react-redux";
import {
  AddNewAdress,
  GetUserAdressBook,
  ResetAddressStatus,
} from "../../Redux/actions/settingsAction";
import Addresscard from "../../Components/Cards/SkuCards/Addresscard";
import { AddNewAddressDialogBox } from "../Settings/tabs/myaddressbook/DialogBoxes";
import useScreenSize from "../../Components/Utils/mobileScreen";
import {
  BlockedDateAction,
  GetProductsByAddress,
  SetSelectedAddress,
} from "../../Redux/actions/skuBookingAction";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import appColors from "../../Assets/AppTheme/appColors";
import moment from "moment";
import FooterAppBarMobile from "../MobileView/FooterMobileView";
import ToastMsgCotainer from "../../Components/ToastMsg/toastMsg";
import CustomConsole from "../../coustomConsole";
export default function SkuSubscription() {
  const iconList = IconListManager();
  const isMobile = useScreenSize();
  const { pathname, search } = useLocation();
  const pageName = new URLSearchParams(search).get("pageName");
  const history = useHistory();
  const dispatch = useDispatch();
  const SkuDetails = useSelector((state) => state.skuBookDetails);
  //settingsAction
  const addressDetails = useSelector((state) => state.settings);
  const skuSubscribeOrderDetails = useSelector(
    (state) => state.skuSubscribeOrderDetails
  );
  //state for storing address ID
  const [addressDetailsID, setAddressDetailsID] = React.useState();
  const [menuDialogOpean, setmenuDialogOpean] = React.useState(false);
  const [selectedDays, setSelectedDays] = React.useState([]);
  const [showImageFlag, setShowImageFlag] = React.useState(false);
  //useState for date selection
  const [currentDate, setCurrentDate] = React.useState(
    moment().tz("Asia/Kolkata").add(1, "days")
  );
  const checkOutDetails = useSelector((state) => state.checkOutDetails);
  const [openEditDialog, setOpenEditDialog] = React.useState(false);
  const [infoCard, setInfoCard] = React.useState(false);
  const [chooseAdressDialog, setchooseAddressDialog] = React.useState(false);
  const SkuProductDetails = useSelector((state) => state.skuBookDetails);
  const [selectedProduct, setSelectedProduct] = React.useState({});
  const [primaryAddress, setPrimaryAddress] = React.useState({});
  const [addAdressDialog, setAddAddressDialog] = React.useState(false);
  const [blockedDates, setBlockedDates] = React.useState([]);
  const [openCrateSelectionDialod, setOpenCrateSelectionDialog] =
    React.useState(false);
  const [checkOutUi, setCheckOutUi] = React.useState(false);
  React.useState(false);

  //api for hitting address details
  React.useEffect(() => {
    if (addressDetails?.addresses?.length === 0) {
      dispatch(GetUserAdressBook());
    }
  }, []);

  //Useeffect for setting address details

  React.useEffect(() => {
    const selectedAddressId = SkuProductDetails.selectedAddressId;
    const storedAddressId = localStorage.getItem("addressId");
    if (
      (!isMobile && storedAddressId === null) ||
      (!isMobile && storedAddressId === undefined)
    ) {
      let AddressData;
      if (addressDetails?.addresses !== undefined) {
        AddressData =
          addressDetails?.addresses.find((item) => item.is_primary === true) ||
          addressDetails?.addresses[0];
        CustomConsole(addressDetails);
      }
      if (AddressData?.address_id !== undefined) {
        setPrimaryAddress(() => AddressData);
        setAddressDetailsID(() => AddressData?.address_id);
        dispatch(SetSelectedAddress(AddressData?.address_id));
        localStorage.setItem("addressId", AddressData?.address_id);
      }
    } else {
      let AddressData;
      if (
        (addressDetails?.addresses !== undefined && storedAddressId === null) ||
        (addressDetails?.addresses !== undefined &&
          storedAddressId === undefined)
      ) {
        AddressData =
          addressDetails?.addresses.find(
            (item) => selectedAddressId === item.address_id
          ) || addressDetails?.addresses[0];
        CustomConsole(addressDetails);
      } else if (addressDetails?.addresses !== undefined) {
        AddressData =
          addressDetails?.addresses.find(
            (item) => storedAddressId == item.address_id
          ) || addressDetails?.addresses[0];
      }
      if (AddressData?.address_id !== undefined) {
        setPrimaryAddress(() => AddressData);
        setAddressDetailsID(() => AddressData?.address_id);
        dispatch(SetSelectedAddress(AddressData?.address_id));
        localStorage.setItem("addressId", AddressData?.address_id);
      }
    }
  }, [addressDetails.addresses]);
  //Closing address dialog if address details added
  const handleAddAddressDialog = () => {
    setAddAddressDialog(false);
  };

  //Function for handling new address added
  const handleAddNewAddress = (details, isPrimary) => {
    dispatch(AddNewAdress(details, isPrimary));
  };

  //React.useEffect for getting available product details if address id changes or first time
  React.useEffect(() => {
    if (
      !SkuProductDetails.addressFlag &&
      SkuProductDetails.selectedAddressId !== null &&
      SkuProductDetails.selectedAddressId !== undefined &&
      SkuProductDetails.selectedAddressId !== ""
    ) {
      dispatch(
        GetProductsByAddress({
          address_id: SkuProductDetails.selectedAddressId,
          validate_dist_stock: false,
        })
      );
      SkuProductDetails.addressFlag = true;
    }
  }, [SkuProductDetails.selectedAddressId]);

  //useLayout effect for redirecting if any subscrription order is placed
  React.useLayoutEffect(() => {
    if (
      skuSubscribeOrderDetails.subscribeOrderDetails !== undefined &&
      skuSubscribeOrderDetails.subscribeOrderDetails.length > 0 &&
      pageName !== "addNew"
    ) {
      history.push("/dashboard/subscriptionList");
    }
  }, []);

  //function for toggle dialog in mobile view
  const toggleDrawer = (newOpen) => {
    setInfoCard(newOpen);
  };

  //useEffect for hitting addressapi
  React.useEffect(() => {
    if (
      !addressDetails.isAddressAdded &&
      SkuProductDetails.selectedAddressId !== null &&
      SkuProductDetails.selectedAddressId !== undefined &&
      SkuProductDetails.selectedAddressId !== ""
    ) {
      dispatch(
        GetProductsByAddress({
          address_id: SkuProductDetails.selectedAddressId,
          validate_dist_stock: false,
        })
      );
      dispatch(ResetAddressStatus());
    }
  }, [addressDetails.isAddressAdded]);

  //For setting error msg flag
  React.useEffect(() => {
    if (SkuDetails?.loading) {
      setShowImageFlag(true);
    }
  }, [SkuDetails?.loading]);
  //useEffect to get blocked dates and block booking
  React.useEffect(() => {
    let validDates =
      SkuProductDetails?.productDetails?.admin_settings?.valid_dates
        ?.valid_dates;

    if (validDates !== undefined && validDates.length > 0) {
      setBlockedDates(validDates);
      // Function to generate an array of dates for the next 7 days
      const getNext7Days = () => {
        const dates = [];
        let currentDate = moment();
        for (let i = 0; i < 8; i++) {
          dates.push(currentDate.format("YYYY-MM-DD"));
          currentDate = currentDate.add(1, "days");
        }
        return dates;
      };
      // Get the next 7 days
      const next7Days = getNext7Days();
      // Filter out the dates that are not in the valid dates array
      const invalidDates = next7Days.filter(
        (date) => !validDates.includes(date)
      );
      dispatch(BlockedDateAction(invalidDates));
    } else if (
      SkuProductDetails?.productDetails?.admin_settings?.valid_dates
        ?.valid_dates.length === 0
    ) {
      setCurrentDate("");
      // dispatch(
      //   ToastMsgCotainer({
      //     error: true,
      //     containerFlag: true,
      //     msg: "No Available dates this week for booking",
      //   })
      // );
    }
  }, [SkuProductDetails?.productDetails]);

  //useEffect to set time option
  React.useEffect(() => {
    // Get today's date
    const today = moment().format("YYYY-MM-DD");
    const tommorrow = moment().add(1, "days").format("YYYY-MM-DD");
    if (blockedDates?.length > 0 && currentDate !== undefined) {
      if (
        blockedDates?.length >= 2 &&
        moment(today).isSame(blockedDates[0], "day")
      ) {
        if (moment(tommorrow).isSame(blockedDates[1], "day")) {
          setCurrentDate(blockedDates[1]);
        } else {
          setCurrentDate(blockedDates[1]);
        }
      } else {
        setCurrentDate(blockedDates[0]);
      }
    }
  }, [blockedDates]);

  return (
    <>
      <Grid
        container
        sx={{
          marginTop: { xs: "4rem" },
          // paddingRight: "5px",
          paddingBottom: "20px",
          // paddingLeft: "5px",
          marginBottom: { sm: "1rem", xs: "8rem" },
        }}
      >
        <Grid container direction="row" spacing={2}>
          <Grid item md={6} xs={12}>
            <BookingAddressCard
              primaryAddress={primaryAddress}
              setchooseAddressDialog={setchooseAddressDialog}
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <SubscriptionSchedule
              selectedDays={selectedDays}
              setSelectedDays={setSelectedDays}
            />
          </Grid>
        </Grid>
        <Grid container>
          <Grid item sm={12} xs={12}>
            <BookingCardsSku
              toggleDrawer={toggleDrawer}
              setCheckOutUi={setCheckOutUi}
              SkuDetails={SkuDetails}
              selectedProduct={selectedProduct}
              setSelectedProduct={setSelectedProduct}
              setInfoCard={setInfoCard}
              currentDate={currentDate}
              infoCard={infoCard}
            />
          </Grid>
        </Grid>
        <Grid
          container
          //   spacing={2}
          justifyContent="center"
          alignItems="center"
          sx={{ marginTop: "1rem" }}
        >
          <Grid
            item
            sm={6}
            md={5}
            xs={12}
            sx={{
              backgroundColor: "transperent",
              top: "auto",
              bottom: 0,
              width: "100%",
              position: "fixed",
            }}
          >
            {SkuDetails?.selectedProduct?.products?.length === 0 &&
              window.innerWidth <= 600 && (
                <AppBar
                  position="fixed"
                  sx={{
                    backgroundColor: "transperent",
                    top: "auto",
                    bottom: 0,
                    height: "3.8rem",
                    width: "100%",
                    position: "fixed",
                    boxShadow: 5,
                  }}
                >
                  <Box sx={{ backgroundColor: appColors.homePageCard }}>
                    <FooterAppBarMobile
                      menuDialogOpean={menuDialogOpean}
                      setmenuDialogOpean={setmenuDialogOpean}
                    />
                  </Box>
                </AppBar>
              )}

            {SkuDetails?.selectedProduct?.products?.length > 0 &&
            selectedDays?.length > 0 ? (
              <Box
                sx={{
                  background:
                    window.innerWidth <= 600
                      ? appColors.checkOutCard
                      : "transparent",
                  bottom: 0,
                  left: { xs: "0", sm: "40%" },
                  // transform: "translate(-50%, -50%)",
                  height: "4.5rem",
                  width: { xs: "100%", sm: "26rem" },
                  position: "fixed",
                  display: "flex",
                  justifyContent: "center",
                  bottom: 0,
                }}
              >
                <CheckOutCardSku pageName="subscribe" />
              </Box>
            ) : null}
          </Grid>
        </Grid>
        {SkuProductDetails?.productDetails === undefined && (
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {showImageFlag && (
              <>
                <img
                  src={failedOrder}
                  alt="Bookwater"
                  style={{ width: "13rem" }}
                />
                <Typography
                  sx={{
                    fontSize: ".9rem",
                    color: appColors.darkGray,
                    fontWeight: "600",
                  }}
                >
                  {" "}
                  Your location is not serviceable!
                </Typography>
                <Typography
                  sx={{
                    fontSize: ".9rem",
                    color: appColors.darkGray,
                    fontWeight: "500",
                    textAlign: "center",
                    wordWrap: "break-word",
                  }}
                >
                  Please try changing your location from Edit Address.
                </Typography>
              </>
            )}
          </Grid>
        )}
      </Grid>
      <Addresscard
        setAddress={setAddressDetailsID}
        setOpenEditDialog={setOpenEditDialog}
        openEditDialog={openEditDialog}
        setchooseAddressDialog={setchooseAddressDialog}
        chooseAdressDialog={chooseAdressDialog}
        addAdressDialog={addAdressDialog}
        address={addressDetailsID}
        primaryAddress={primaryAddress}
        setCurrentAddress={setPrimaryAddress}
        setAddAddressDialog={setAddAddressDialog}
      />
      <AddNewAddressDialogBox
        open={addAdressDialog}
        handleClose={handleAddAddressDialog}
        handleAddNewAddress={handleAddNewAddress}
        setAddAddressDialog={setAddAddressDialog}
      />

      {/* <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={checkOutDetails?.pageLoading || SkuDetails?.pageLoading}
      >
        <Box
          sx={{
            height: "80vh",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img
            src={BiconLoader}
            alt="Loading..."
            style={{
              height: "50px",
              width: "50px",
              backgroundColor: "transparent",
            }}
          />
        </Box>
      </Backdrop> */}
      <ToastMsgCotainer />
    </>
  );
}
