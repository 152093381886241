import React from "react";
import { useSelector } from "react-redux";

const ImageLoader = ({ filename }) => {
  const imageFilesstore = useSelector(
    (state) => state.getFilesDownload.appImages
  );
  const loadImage = () => {
    try {
      // Retrieve the image URL from local storage
      const storedImageURL = imageFilesstore[`image_${filename}`];

      if (storedImageURL) {
        // Display the image in the UI using the stored URL
        return (
          <img
            src={storedImageURL}
            alt={filename}
            style={{
              width: "100%", // Allow the width to adjust according to the aspect ratio
              height: "5rem", // Allow the height to adjust according to the aspect ratio
              objectFit: "contain",
            }}
          />
        );
      } else {
        // Handle the case when the image URL is not found in local storage
        return (
          <img
            alt=""
            src={process.env.PUBLIC_URL + "/bwLogo2.png"}
            style={{ objectFit: "contain", height: "30px" }}
          />
        );
      }
    } catch (error) {
      console.error("Error loading image:", error);
      // Handle errors, e.g., display an error message
      return <p>Error loading image</p>;
    }
  };

  return <div>{loadImage()}</div>;
};

export default ImageLoader;
