/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */
import React from "react";
import GiftBOX from "../../../Assets/newMobileIcon/menu/giftBox.svg";
import { Box, Typography } from "@mui/material";
import appTheme from "../../../Assets/AppTheme/appTheme";
import SvgComponent from "../../../Assets/SvgFiles/GiftCards/giftCards";
import appColors from "../../../Assets/AppTheme/appColors";
export default function AvailedGiftCardList() {
  return (
    <>
      <Box sx={cardStyles.cardStyle}>
        <Box sx={cardStyles.cardHeader}>
          <Box>
            <Typography sx={cardStyles.header}>
              10% Discount upto ₹1000
            </Typography>
            <Typography sx={{ fontSize: ".7rem", margin: 0 }}>
              single treat
            </Typography>
          </Box>
          <Box sx={cardStyles.subHeader}>
            <Typography>Claimed</Typography>
          </Box>
        </Box>
        <Box sx={{ padding: ".5rem" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={cardStyles.subHeaderText}>Grid ID: 1233</Typography>
            <Typography sx={cardStyles.subHeaderText}>₹2000</Typography>
          </Box>
          <Box>
            <Typography sx={{ margin: 0, fontSize: ".9rem" }}>
              Description : This gift card offers 20% off discount for next
              order it valid next 40 days only, its single treat discount you
              can only use this.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ padding: "0 .5rem" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={cardStyles.subHeaderText}>Grid ID: 1233</Typography>
            <Typography sx={cardStyles.subHeaderText}>₹2000</Typography>
          </Box>
        </Box>
        <Box sx={{ padding: "0 .5rem" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={cardStyles.subHeaderText}>
              Message sent by you : Wish happy birthday Noor
            </Typography>
          </Box>
        </Box>
        <Box sx={{ position: "absolute", top: 50, right: 100 }}>
          <img src={GiftBOX} alt="Bookwater" />
        </Box>
      </Box>

      <Box sx={cardStyles.cardStyle}>
        <Box sx={cardStyles.cardHeader}>
          <Box>
            <Typography sx={cardStyles.header}>
              10% Discount upto ₹1000
            </Typography>
            <Typography sx={{ fontSize: ".7rem", margin: 0 }}>
              single treat
            </Typography>
          </Box>
          <Box sx={cardStyles.subHeader}>
            <Typography>Claimed</Typography>
          </Box>
        </Box>
        <Box sx={{ padding: ".5rem" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={cardStyles.subHeaderText}>Grid ID: 1233</Typography>
            <Typography sx={cardStyles.subHeaderText}>₹2000</Typography>
          </Box>
          <Box>
            <Typography sx={{ margin: 0, fontSize: ".9rem" }}>
              Description : This gift card offers 20% off discount for next
              order it valid next 40 days only, its single treat discount you
              can only use this.
            </Typography>
          </Box>
        </Box>
        <Box sx={{ padding: "0 .5rem" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={cardStyles.subHeaderText}>Grid ID: 1233</Typography>
            <Typography sx={cardStyles.subHeaderText}>₹2000</Typography>
          </Box>
        </Box>
        <Box sx={{ padding: "0 .5rem" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={cardStyles.subHeaderText}>
              Message sent by you : Wish happy birthday Noor
            </Typography>
          </Box>
        </Box>
        <Box sx={{ position: "absolute", top: 50, right: 100 }}>
          {/* <img src={GiftBOX} alt="Bookwater" /> */}
          <SvgComponent color={appColors.Failed}/>
        </Box>
      </Box>
    </>
  );
}

const cardStyles = {
  cardStyle: {
    boxShadow: "0 2px 2px 0 rgba(0,0,0,0.2)",
    transition: "0.3s",
    width: { xs: "100%", md: "50%", lg: "40%" },
    height: "auto",
    borderRadius: "1rem",
    position: "relative",
    background: "#fff",
    padding: "0 0 0.5rem 0",
    marginBottom: "1rem",
  },
  cardHeader: {
    background: "linear-gradient(90deg, #E41374 10%, #FE7FBA 100%)",
    width: "100%",
    height: "25%",
    borderRadius: "1rem 1rem 0 0",
    display: "flex",
    alignItems: "center",
    color: "#fff",
    justifyContent: "space-between",
    padding: "0 .8rem",
  },
  header: {
    margin: 0,
    fontSize: appTheme.fontSizeLarge,
    fontWeight: "bold",
  },
  subHeaderText: {
    margin: 0,
    fontSize: appTheme.fontSizeSmall,
    fontWeight: "bold",
  },
  subHeader: {
    height: "70%",
    width: "auto",
    background: "#E41475",
    marginBottom: ".8rem",
    padding: "0 .5rem",
    borderRadius: "0 0 .5rem .5rem",
    boxShadow: "0 2px 2px 0 rgba(0,0,0,0.2)",
  },
};
