/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description Custom Text Field (Password Field)
 * @date 01/12/23
 * @version 0.0.1 - Initial Release
 */

import React from "react";
import { Box, Button, Card, Stack, Typography } from "@mui/material";
import appColors from "../../../Assets/AppTheme/appColors";
import DeleteIcon from "@mui/icons-material/Delete";
import ImageLoader from "../../Utils/imageLoader";
import RequiredButton from "../../SkuButtons/SkuRequiredButton";
import ReturnButton from "../../SkuButtons/SKUReturnButton";
import { useDispatch, useSelector } from "react-redux";
import {
  AddCheckOutRequiredQty,
  AddCheckOutReturnQty,
  ReduseCheckOutRequiredQty,
  ReduseCheckOutReturnQty,
} from "../../../Redux/actions/checkOutAction";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { ToastMsgState } from "../../../Redux/actions/toastMsg";
import CustomConsole from "../../../coustomConsole";
import appTheme from "../../../Assets/AppTheme/appTheme";
import useProductImages from "../../Utils/productImages";
export default function SkuOrderDetailsCard({ handelDeleteProduct }) {
  const dispatch = useDispatch();
  const checkoutDetails = useSelector((state) => state.checkOutDetails);
  const SkuSelectedProducts = useSelector((state) => state.skuBookDetails);
  const checkOutDetails = useSelector(
    (state) => state.checkOutDetails.checkOutDetails
  );
  const { pathname, search } = useLocation();
  const pageName = new URLSearchParams(search).get("pageName");
  //HandelAddQuantity for adding quantity
  const handelAddQuantity = (data) => {
    CustomConsole(data);
    if (
      (!checkoutDetails?.loading &&
        data?.quantity?.selected < data?.max_book_qty &&
        data?.quantity?.selected <
          parseInt(data?.quantity?.existing_max_dist_stock)) ||
      (pageName &&
        pageName.includes("editSubscribe") &&
        !checkoutDetails?.loading &&
        data?.quantity?.selected < data?.max_book_qty) ||
      (pageName &&
        pageName === "subscribe" &&
        !checkoutDetails?.loading &&
        data?.quantity?.selected < data?.max_book_qty)
    ) {
      dispatch(AddCheckOutRequiredQty(data));
    } else if (
      data?.quantity?.selected == data?.max_book_qty ||
      data?.quantity?.selected ==
        parseInt(data?.quantity?.existing_max_dist_stock)
    ) {
      dispatch(
        ToastMsgState({
          error: true,
          containerFlag: true,
          msg: `Maximum order limit reached`,
        })
      );
    }
  };

  //function for handling reduse ordr quantity action
  const handelReduseQuantity = (data) => {
    if (
      !checkoutDetails?.loading &&
      data.quantity.selected > data.min_book_qty
    ) {
      dispatch(ReduseCheckOutRequiredQty(data));
    } else if (!checkoutDetails?.loading) {
      dispatch(
        ToastMsgState({
          error: true,
          containerFlag: true,
          msg: `Minimum order limit reached`,
        })
      );
    }
  };

  //function for handling Add Return quantityaction
  const handelAddReturnQuantity = (data) => {
    if (
      !checkoutDetails?.loading &&
      data.return.selected < data.return.max_return &&
      data?.return?.selected < data.quantity?.selected * data?.sku_qty
    ) {
      dispatch(AddCheckOutReturnQty(data));
    } else {
      if (!checkoutDetails?.loading) {
        dispatch(
          ToastMsgState({
            error: true,
            containerFlag: true,
            msg: `Not having enough cans to return`,
          })
        );
      }
    }
  };

  //function for handling Reduse Return quantityaction
  const handelReduseReturnQuantity = (data) => {
    if (!checkoutDetails?.loading && data.return.selected > 0) {
      dispatch(ReduseCheckOutReturnQty(data));
    }
  };

  //hook to download images
  const { images, isLoading, error } = useProductImages(
    checkOutDetails?.valid_products,
    "booking"
  );

  console.log(checkoutDetails);
  return (
    <>
      <Typography
        sx={{
          color: appColors.headerTextColor,
          marginBottom: { xs: ".5rem" },
          fontWeight: "bold",
          fontSize: appTheme.fontSizeMedium,
        }}
      >
        Order Details
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          //   margin: { xs: "0.5rem 1rem", sm: "1rem 0" },
          flexWrap: "wrap",
          justifyContent: { xs: "center", sm: "flex-start" },
          boxShadow: 5,
          alignItems: "space-between",
          borderRadius: ".7rem",
        }}
      >
        {checkOutDetails?.valid_products?.map((data, index) => (
          <Card
            key={index}
            sx={{
              padding: "0 0rem 0 0",
              width: "100%",
              bgcolor: appColors.cardColor,
              borderStyle:
                checkOutDetails?.valid_products?.length > 1 && index === 0
                  ? "none none dashed none"
                  : index !== checkOutDetails?.valid_products?.length - 1 &&
                    checkOutDetails?.valid_products?.length > 1
                  ? "none none dashed none"
                  : "none none none none",
              borderColor: appColors.Textgray,
              borderRadius:
                checkOutDetails?.valid_products?.length > 1 && index === 0
                  ? ".7rem .7rem 0 0"
                  : index === checkOutDetails?.valid_products?.length - 1 &&
                    checkOutDetails?.valid_products?.length > 1
                  ? "0 0  .7rem .7rem"
                  : index !== checkOutDetails?.valid_products?.length - 1 &&
                    checkOutDetails?.valid_products?.length > 1
                  ? "0 0 0 0"
                  : ".7rem .7rem 0.7rem 0.7rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                color: appColors.textColorNew,
                alignItems: "center",
                bgcolor: "#BFEFFF",
                height: "1.5rem",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  color: appColors.walletCard,

                  alignItems: "center",
                  // color: appColors.secondaryText,
                  paddingLeft: ".8rem",
                  // width:"50%"
                }}
              >
                <Typography
                  sx={{
                    fontSize: appTheme.fontSizeSmall,
                    fontWeight: "bold",
                    // width: "50%", // Set the width of the Typography component
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap", // Prevent wrapping
                    overflow: "hidden", // Hide the overflowed text
                  }}
                >
                  {data?.name}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  color: appColors.textColorNew,
                  alignItems: "center",
                  color: appColors.secondaryText,
                  paddingLeft: ".8rem",
                  paddingRight: ".8rem",
                  // width: "100%", // Ensure the Box takes full width
                }}
              >
                {data?.sku_qty > 1 && data?.quantity?.selected > 0 && (
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      color: appColors.grayText,
                      fontSize: appTheme.fontSizeSmall,
                    }}
                  >
                    <span>{data.quantity.selected} Pack |</span>
                    {data.quantity.selected >= data.min_book_qty ? (
                      <span>
                        &nbsp;
                        {data.quantity.selected * data.sku_qty} Units
                      </span>
                    ) : null}
                  </Typography>
                )}
                {data?.sku_qty === 1 && (
                  <Typography
                    sx={{
                      fontWeight: "bold",
                      color: appColors.grayText,
                      fontSize: appTheme.fontSizeSmall,
                    }}
                  >
                    <span>Minimum Qty {data?.min_book_qty}</span>
                  </Typography>
                )}
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                color: appColors.textColorNew,
                alignItems: "center",
                justifyContent: "center",
                color: appColors.secondaryText,
                marginLeft: ".8rem",
                // width: "100%", // Ensure the Box takes full width
              }}
            >
              {data?.quantity?.is_valid && data?.return?.is_valid ? (
                <Typography
                  sx={{
                    color: appColors.headerTextColor,
                    fontWeight: "600",
                    fontSize: appTheme.fontSizeSmall,
                    paddingRight: ".7rem",
                    // width: "50%", // Set the width of the Typography component
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap", // Prevent wrapping
                    overflow: "hidden", // Hide the overflowed text
                    textAlign: "center",
                  }}
                >
                  Price&nbsp;
                  <span style={{ color: appColors.Textgray }}>₹</span>
                  {data?.price?.mrp !== data?.price?.sp &&
                    data?.price?.mrp *
                      data?.sku_qty *
                      data?.quantity?.selected >
                      data?.product_price && (
                      <span
                        style={{
                          textDecoration: "line-through",
                          color: appColors.Textgray,
                          fontWeight: "bold",
                          fontSize: appTheme.fontSizexs,
                        }}
                      >
                        {" "}
                        {data?.price?.mrp *
                          data?.sku_qty *
                          data?.quantity?.selected}
                      </span>
                    )}{" "}
                  <span style={{ color: appColors.headerTextColor }}>
                    {parseFloat(data?.product_price).toFixed(2)}{" "}
                    {data?.deposit?.price > 0 &&
                      `+ ₹${data?.deposit?.price} (Refundable Deposit)`}
                  </span>
                </Typography>
              ) : (
                <Typography
                  sx={{
                    color: appColors.Failed,
                    fontWeight: "600",
                    fontSize: appTheme.fontSizeSmall,
                    paddingRight: ".7rem",
                    // width: "50%", // Set the width of the Typography component
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap", // Prevent wrapping
                    overflow: "hidden", // Hide the overflowed text
                    textAlign: "center",
                  }}
                >
                  {data?.quantity?.msg}
                  {data?.return?.msg}
                </Typography>
              )}
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "0 0 0 0.5rem",
              }}
            >
              <Box
                sx={{
                  width: "5rem",
                }}
              >
                <ImageLoader filename={data?.image} />
              </Box>
              <Box sx={{ width: "100%", padding: "0 1rem" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: ".5rem",
                  }}
                >
                  <Typography
                    sx={{
                      color: appColors.darkGray,
                      fontSize: appTheme.fontSizeMedium,
                    }}
                  >
                    Required
                  </Typography>
                  <RequiredButton
                    handelAddQuantity={handelAddQuantity}
                    handelReduseQuantity={handelReduseQuantity}
                    data={data}
                  />
                </Box>
                {SkuSelectedProducts?.selectedProduct?.booking_type === 1 ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      sx={{
                        color: appColors.darkGray,
                        fontSize: appTheme.fontSizeMedium,
                      }}
                    >
                      Return
                    </Typography>
                    <ReturnButton
                      handelAddReturnQuantity={handelAddReturnQuantity}
                      handelReduseReturnQuantity={handelReduseReturnQuantity}
                      data={data}
                    />
                  </Box>
                ) : (
                  ""
                )}
              </Box>
              <Box
                sx={{ marginLeft: ".6rem", zIndex: 0 }}
                onClick={() => handelDeleteProduct(data)}
              >
                <DeleteIcon
                  sx={{
                    color: appColors.Failed,
                    height: "2rem",
                    width: "2rem",
                  }}
                />
              </Box>
            </Box>
          </Card>
        ))}
        {checkOutDetails?.invalid_products?.map((data, index) => (
          <Card
            key={index}
            sx={{
              padding: "0 0.5rem 0 0",
              width: "100%",
              bgcolor: appColors.cardColor,
              borderStyle:
                checkOutDetails?.invalid_products?.length > 1 && index === 0
                  ? "none none dashed none"
                  : "none none none none",
              borderColor: appColors.Textgray,
              borderRadius:
                checkOutDetails?.invalid_products?.length > 1 && index === 0
                  ? ".7rem .7rem 0 0"
                  : index === checkOutDetails?.invalid_products?.length - 1 &&
                    checkOutDetails?.invalid_products?.length > 1
                  ? "0 0  .7rem .7rem"
                  : ".7rem .7rem 0.7rem 0.7rem",
              padding: "0 0.5rem 0 0",
              position: "relative",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                color: appColors.textColorNew,
                fontWeight: "bold",
                color: appColors.secondaryText,
                marginLeft: "1rem",
              }}
            >
              <Typography>{data?.capacity}</Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <ImageLoader filename={data?.image} />
              </Box>
              <Box sx={{ width: "100%", padding: "0 1rem" }}>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginBottom: ".5rem",
                  }}
                >
                  <Typography>Required</Typography>
                  <RequiredButton
                    handelAddQuantity={handelAddQuantity}
                    handelReduseQuantity={handelReduseQuantity}
                    data={data}
                  />
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>Return</Typography>
                  <ReturnButton
                    handelAddReturnQuantity={handelAddReturnQuantity}
                    handelReduseReturnQuantity={handelReduseReturnQuantity}
                    data={data}
                  />
                </Box>
              </Box>
              <Box>
                <Typography sx={{ color: appColors.darkGray }}>
                  ₹{data?.final_price}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                position: "absolute",
                bottom: 0,
                left: 0,
                right: 0,
                backgroundColor: "#bfefffab",
                overflow: "hidden",
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ color: appColors.Failed }}>
                Out of Stock&nbsp;&nbsp;
                <span>
                  <DeleteIcon
                    sx={{ color: appColors.Failed }}
                    onClick={() => handelDeleteProduct(data)}
                  />
                </span>
              </Typography>
            </Box>
          </Card>
        ))}
      </Box>
    </>
  );
}
