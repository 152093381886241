/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author Gowtham Prasath
 * @description ordersTopBar.jsx is the parent file for the Orders page
 * @date 24/06/23
 * @version 0.0.1 - Initial Release
 */
import * as React from "react";
import * as MaterialBase from "@mui/base";
import * as getDepositAction from "../../Redux/actions/getDepositHistoryAction.js";
import { useDispatch, useSelector } from "react-redux";
import Box from "@mui/material/Box";
import { styled } from "@mui/system";
import SwipeableViews from "react-swipeable-views";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import FilterDepositDetails from "../Reports/FilterDepositDetails.jsx";
import TuneSharpIcon from "@mui/icons-material/TuneSharp";
import Transactions from "./Transactions";
import { GetWalletTransactionDetails } from "../../Redux/actions/transactionsAction";
import NewWalletTransactionCard from "./NewWalletTransaction";
import NewDepositCard from "./NewDepositTransaction.jsx";
import appColors from "../../Assets/AppTheme/appColors.js";
import PageNavigation from "../../Components/CommonComponents/PageNavigationDetails.jsx";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min.js";
import CustomConsole from "../../coustomConsole.js";
import { Button } from "@mui/material";
import TransactionFilterDialog from "../../Components/DialogBoxes/FilterDialogBox/TransactionFilter.jsx";
import moment from "moment";
export default function TransactionMainComponent() {
  const dispatch = useDispatch();
  const { pathname, search } = useLocation();
  const [filterDialogOpen, setfilterDialogOpen] = React.useState(false);
  const pageName = new URLSearchParams(search).get("pageName");
  const transactionDetails = useSelector((state) => state.transactionDetails);
  const data = useSelector((state) => state.depositDetails);
  const [pageNumber, setPageNumber] = React.useState(1);
  const [referLoading, setReferLoading] = React.useState(true);
  const [depositPageNumber, setDepositPageNumber] = React.useState(1);
  const [value, setvalue] = React.useState(0);
  const [openFilter, setOpenFilter] = React.useState(false);
  const [filter, setFilter] = React.useState({
    dateFilter: "DESC",
    paymentStatusFilter: null,
  });
  const [walletFilter, setWalletFilter] = React.useState({
    paymentStatusFilter: null,
    paymentMethod: null,
    from: moment().subtract(180, "days").format("YYYY-MM-DD"),
    to: moment().add(10, "days").format("YYYY-MM-DD"),
    sortorder: "DESC",
  });
  React.useEffect(() => {
    if (value === 1) {
      dispatch(
        GetWalletTransactionDetails({
          pageNumber: pageNumber - 1,
          payMethod: walletFilter.paymentMethod,
          status:
            walletFilter.paymentStatus === false
              ? 0
              : walletFilter.paymentStatus
              ? 1
              : null,
          from: walletFilter.from,
          to: walletFilter.to,
          sortorder: walletFilter.sortorder,
        })
      );
    }
  }, [pageNumber, value]);

  React.useEffect(() => {
    if (value === 2) {
      dispatch(
        getDepositAction.GetDepositHistoryAction({
          sort: filter.dateFilter,
          payment_status: filter.paymentStatusFilter,
          page_number: depositPageNumber - 1,
        })
      );
    }
  }, [depositPageNumber, value]);

  CustomConsole(transactionDetails);
  const Tab = styled(MaterialBase.TabUnstyled)`
    font-family: sans-serif;
    cursor: pointer;
    font-size: 1rem;
    font-weight: bold;
    background-color: transparent;
    width: 100%;
    ${"" /* padding: 12px 16px; */}
    ${"" /* margin: 6px 6px; */}
    ${"" /* padding: 12px 16px; */}
    padding: 9px;
    border: none;
    display: flex;
    justify-content: center;
    &:hover {
      border-bottom: 3px solid #30a7ff;
    }

    &:focus {
      border-bottom: 3px solid #30a7ff;
    }

    &.${MaterialBase.tabUnstyledClasses.selected} {
      color: #30a7ff;
      border-bottom: 3px solid #30a7ff;
    }
  `;

  const TabsList = styled(MaterialBase.TabsListUnstyled)`
    min-width: 200px;
    ${"" /* width: fit-content; */}
    ${"" /* margin-right: 10px; */}
    background-color:transperent;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: space-between;
  `;

  const depositDataList = data.depositData.length;

  const handleProcessTabshange = (event, newProcessValue) => {
    setvalue(newProcessValue);
    CustomConsole("newProcessValue: " + newProcessValue);
  };

  const handleFilterOpen = () => {
    setfilterDialogOpen(true);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleFilterSubmit = (filterObject) => {
    console.log(filterObject);
    setPageNumber(1);
    setDepositPageNumber(1);
    if (value === 2) {
      setFilter((prev) => ({
        ...prev,
        dateFilter: filterObject.orderBy,
        paymentStatusFilter:
          filterObject.status === "SUCCESS"
            ? true
            : filterObject.status === "FAILED"
            ? false
            : null,
      }));
      //${req.page_number}&date=${req.date}&payment_status=${req.payment_status}`
      dispatch(
        getDepositAction.GetDepositHistoryAction({
          page_number: 0,
          sort: filterObject.orderBy,
          payment_status:
            filterObject.status === "SUCCESS"
              ? true
              : filterObject.status === "FAILED"
              ? false
              : null,
        })
      );
    } else if (value === 1) {
      const paymentMethodValue =
        filterObject.paymentMethod === "PAYSHARP"
          ? 2
          : filterObject.paymentMethod === "RAZORPAY"
          ? 1
          : filterObject.paymentMethod === "WATERWALLET"
          ? 0
          : null;
      const paymentStatus =
        filterObject.status === "SUCCESS"
          ? true
          : filterObject.status === "FAILED"
          ? false
          : undefined;
      setWalletFilter((prev) => ({
        ...prev,
        dateFilter: filterObject.orderBy,
        paymentStatusFilter: paymentStatus,
        paymentMethod: paymentMethodValue,
        from: filterObject.fromValue,
        to: filterObject.toValue,
        sortorder: filterObject.orderBy,
      }));
      console.log(paymentStatus);
      dispatch(
        GetWalletTransactionDetails({
          pageNumber: pageNumber - 1,
          payMethod: paymentMethodValue,
          status: paymentStatus === false ? 0 : paymentStatus ? 1 : null,
          from: filterObject.fromValue,
          to: filterObject.toValue,
          sortorder: filterObject.orderBy,
        })
      );
    }
  };

  CustomConsole(value == "1");
  const handleSwipeChange = (index) => {
    setvalue(index);
    CustomConsole(index);
  };

  //useEffect to set water drop tab
  React.useEffect(() => {
    if (pageName === "wallet") {
      setvalue(1);
    } else {
      setvalue(0);
    }
  }, [pageName]);

  //function for loading icon and clering selected products after orders page
  React.useEffect(() => {
    setTimeout(() => {
      setReferLoading(false);
    }, 2000);
  }, []);
  return (
    <Box
      sx={{
        marginTop: "4rem",
        marginBottom: { lg: "0", md: "0", sm: "4rem", xs: "4rem" },
      }}
    >
      <Box>
        <MaterialBase.TabsUnstyled
          value={value}
          onChange={handleProcessTabshange}
        >
          <TabsList
            sx={{
              backgroundColor: "transparent",
              boxShadow: "none",
              display: "flex",
              flexDirection: { md: "row", xs: "row" },
              borderBottom: "2px solid #58595b",
            }}
          >
            <Tab
              value={0}
              sx={{ color: appColors.Textgray }}
              onClick={() => setPageNumber(1)}
            >
              <span>
                <CurrencyRupeeIcon color="info" sx={{ marginRight: ".3rem" }} />
              </span>
              Orders
            </Tab>
            <Tab
              value={1}
              sx={{ color: appColors.Textgray }}
              onClick={() => setPageNumber(1)}
            >
              <span>
                {" "}
                <CurrencyRupeeIcon color="info" sx={{ marginRight: ".3rem" }} />
              </span>
              Wallet{" "}
            </Tab>
            <Tab
              value={2}
              sx={{ color: appColors.Textgray }}
              onClick={() => setPageNumber(1)}
            >
              <span>
                {" "}
                <CurrencyRupeeIcon color="info" sx={{ marginRight: ".3rem" }} />
              </span>
              Deposits
            </Tab>
            {/* <Tab value={options.SUBSCRIPTION_ORDER}>Subscription</Tab> */}
          </TabsList>
        </MaterialBase.TabsUnstyled>
      </Box>
      <SwipeableViews index={value} onChangeIndex={handleSwipeChange}>
        <Box>{value === 0 ? <Transactions /> : ""} </Box>
        <Box>
          {value === 1 && (
            <>
              <Box
                sx={{
                  marginTop: "16px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: { sm: "10px", xs: "10px" },
                }}
              >
                <PageNavigation
                  setPageNumber={setPageNumber}
                  pageNumber={pageNumber}
                  pageList={transactionDetails.pageStatus}
                />
                <Box
                  sx={{
                    height: "2.5rem",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    size="small"
                    startIcon={<TuneSharpIcon />}
                    sx={{
                      width: "auto",
                      backgroundColor: appColors.textColorNew,
                      marginTop: "5px",
                    }}
                    onClick={handleFilterOpen}
                  >
                    Filter
                  </Button>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  flexDirection: "row",
                  justifyContent: "space-around",
                  gap: 2,
                  marginBottom: "1rem",
                  maxHeight: window.innerHeight,
                  overflowY: "auto",
                  "&::-webkit-scrollbar": {
                    display: "none", // Webkit browsers
                  },
                }}
              >
                <NewWalletTransactionCard
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                />
              </Box>
            </>
          )}
        </Box>

        {value === 2 && (
          <>
            {data.depositData.length >= 1 ? (
              <Box
                sx={{
                  marginTop: "16px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  marginBottom: { sm: "10px", xs: "10px" },
                }}
              >
                <PageNavigation
                  setPageNumber={setDepositPageNumber}
                  pageNumber={depositPageNumber}
                  pageList={depositDataList < 10 ? true : false}
                />
                <Box
                  sx={{
                    height: "2.5rem",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    size="small"
                    startIcon={<TuneSharpIcon />}
                    sx={{
                      width: "auto",
                      backgroundColor: appColors.textColorNew,
                      marginTop: "5px",
                    }}
                    onClick={handleFilterOpen}
                  >
                    Filter
                  </Button>
                </Box>
              </Box>
            ) : (
              ""
            )}

            {openFilter ? (
              <FilterDepositDetails
                open={openFilter}
                handleCloseFilter={handleCloseFilter}
                handleFilterSubmit={handleFilterSubmit}
              />
            ) : (
              ""
            )}

            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                maxHeight: window.innerHeight,
                overflowY: "auto",
                "&::-webkit-scrollbar": {
                  display: "none", // Webkit browsers
                },
                flexDirection: "row",
                justifyContent: "space-around",
                gap: 2,
              }}
            >
              <NewDepositCard
                depositPageNumber={pageNumber}
                setDepositPageNumber={setPageNumber}
              />
            </Box>
          </>
        )}
      </SwipeableViews>
      {value === 1 && (
        <TransactionFilterDialog
          filterDialogOpen={filterDialogOpen}
          setfilterDialogOpen={setfilterDialogOpen}
          handleFilterSubmit={handleFilterSubmit}
          setPageNumber={setPageNumber}
          value={value}
        />
      )}
      {value === 2 && (
        <TransactionFilterDialog
          filterDialogOpen={filterDialogOpen}
          setfilterDialogOpen={setfilterDialogOpen}
          handleFilterSubmit={handleFilterSubmit}
          setPageNumber={setPageNumber}
          value={value}
        />
      )}
    </Box>
  );
}
