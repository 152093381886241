/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import { Grid, Typography } from "@mui/material";
import appColors from "../../../../Assets/AppTheme/appColors";
import "../dialogStyles.css";
import ImageLoader from "../../../Utils/imageLoader";
export default function ProductInfoDialog({
  toggleDrawer,
  open,
  setOpen,
  selectedProduct,
  bookingType,
}) {
  //function for closing dialog
  const handelClose = (event) => {
    event.preventDefault();
    toggleDrawer(false);
  };

  const crateStyles = {};
  return (
    <div>
      <React.Fragment>
        <Drawer
          anchor={"bottom"}
          open={open}
          onClose={(e) => handelClose(e)}
          className="drawerWithRadius"
        >
          <Box
            sx={{
              padding: ".5rem 1rem",
              backgroundColor: appColors.cardColor,
            }}
          >
            <Grid container sx={{ margin: "1rem 0" }}>
              <Grid item xs={3}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    margin: ".2rem",
                    height: "100%",
                  }}
                >
                  <ImageLoader filename={selectedProduct?.image} />
                </Box>
              </Grid>
              <Grid item xs={9}>
                <Box>
                  {(selectedProduct?.quantity == 0 &&
                    selectedProduct?.sku_qty[0] > 1) ||
                  (selectedProduct?.quantity === undefined &&
                    selectedProduct?.sku_qty[0] > 1) ? (
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: appColors.headerTextColor,
                        fontSize: ".9rem",
                      }}
                    >
                      {selectedProduct?.name} &nbsp;&nbsp;&nbsp;
                      <span style={{ color: appColors.darkGray }}>
                        {selectedProduct.min_book_qty} Pack|
                      </span>
                      <span style={{ color: appColors.darkGray }}>
                        {selectedProduct.sku_qty[0]} Units
                      </span>
                    </Typography>
                  ) : null}
                  {selectedProduct?.sku_qty[0] > 1 &&
                    selectedProduct?.quantity > 0 && (
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          color: appColors.headerTextColor,
                          fontSize: ".9rem",
                        }}
                      >
                        {selectedProduct?.name}{" "}
                        <span>{selectedProduct.quantity} Pack|</span>
                        {selectedProduct.quantity >=
                        selectedProduct.min_book_qty ? (
                          <span>
                            {selectedProduct.quantity *
                              selectedProduct.sku_qty[0]}{" "}
                            Units
                          </span>
                        ) : null}
                      </Typography>
                    )}
                  {selectedProduct?.sku_qty[0] === 1 && (
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        color: appColors.headerTextColor,
                        fontSize: ".9rem",
                      }}
                    >
                      {selectedProduct?.name} &nbsp;&nbsp;&nbsp;
                      {/* <span style={{ color: appColors.darkGray }}>
                        Minimum Qty {selectedProduct.min_book_qty}
                      </span> */}
                    </Typography>
                  )}
                </Box>
                <Box>
                  <Typography
                    sx={{ color: appColors.darkGray, fontSize: ".8rem" }}
                  >
                    Description:{selectedProduct?.description}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box>
                    <Typography>
                      {(selectedProduct.refill.mrp !==
                        selectedProduct?.refill.sp &&
                        bookingType === 1) ||
                      (selectedProduct.one_time_sale.mrp !==
                        selectedProduct?.one_time_sale.sp &&
                        bookingType === 2) ? (
                        <span
                          style={{
                            textDecoration: "line-through",
                            color: appColors.darkGray,
                            fontWeight: "bold",
                            fontSize: ".8rem",
                            marginRight: "1rem",
                          }}
                        >
                          ₹&nbsp;
                          {bookingType === 1
                            ? selectedProduct.refill.mrp
                            : selectedProduct?.one_time_sale.mrp}
                        </span>
                      ) : null}
                      <span
                        style={{
                          color: appColors.headerTextColor,

                          fontWeight: "bold",
                          fontSize: ".9rem",
                        }}
                      >
                        ₹
                        {bookingType === 1
                          ? selectedProduct?.refill?.sp
                          : selectedProduct?.one_time_sale?.sp}
                      </span>
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>
                      <span
                        style={{
                          color: appColors.darkGray,
                          fontWeight: "bold",
                          fontSize: ".8rem",
                        }}
                      >
                        Min Qty:{selectedProduct?.min_book_qty}
                      </span>
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box>
                    <Typography>
                      <span
                        style={{
                          color: appColors.darkGray,
                          fontWeight: "bold",
                          fontSize: ".8rem",
                        }}
                      >
                        Material: {selectedProduct?.material}
                      </span>
                    </Typography>
                  </Box>
                  <Box>
                    <Typography>
                      <span
                        style={{
                          color: appColors.darkGray,
                          fontWeight: "bold",
                          fontSize: ".8rem",
                        }}
                      >
                        Capacity: {selectedProduct?.capacity}
                      </span>
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Button
              size="small"
              variant="contained"
              sx={{
                background: appColors.textColorNew,
                color: "#fff",
                width: "100%",
                marginTop: ".5rem",
                borderRadius: "10px",
              }}
              onClick={handelClose}
            >
              Close
            </Button>
          </Box>
          {/* <img
            src={DownIcon}
            onClick={(e) => handelClose(e)}
            style={{
              position: "absolute",
              top: "-.2rem",
              right: "45%",
              height: "2.3rem",
              width: "3rem",
              color: appColors.darkGray,
            }}
            alt=""
          /> */}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
