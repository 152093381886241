/**
 * @author Bookwater Tech
 * @description Booking Page
 * @copyright Bookwater tech pvt ltd
 */

import React from "react";
import IconListManager from "../../Assets/AppTheme/appIcons";

export function SidebarData() {
  const SidebarDatas = [
    {
      id: 0,
      title: "Home",
      path: "/dashboard/home",
      icon: IconListManager().home,
    },

    {
      id: 1,
      title: "Book Now",
      path: "/dashboard/booknow",
      icon: IconListManager().deliveryCanIcon,
    },
    {
      id: 2,
      title: "My Orders",
      path: "/dashboard/myorders",
      icon: IconListManager().myOrders,
    },
    {
      id: 3,
      title: "Transaction",
      path: "/dashboard/transactions",
      icon: IconListManager().transaction,
    },
    {
      id: 4,
      title: "Accounts",
      path: "/dashboard/myaccounts",
      icon: IconListManager().bankIcon,
    },
    {
      id: 5,
      title: "Deposits",
      path: "/dashboard/deposit",
      icon: IconListManager().depositIcon,
    },
    {
      id: 6,
      title: "Wallet",
      path: "/dashboard/wallet",
      icon: IconListManager().walletIcon,
    },

    {
      id: 7,
      title: "Subscription",
      path: "/dashboard/subscriptionList",
      icon: IconListManager().subcribeIcon,
    },
    {
      id: 8,
      title: "Refer & Earn",
      path: "/dashboard/referandearn",
      icon: IconListManager().referIcon,
    },
    {
      id: 9,
      title: "Support",
      path: "/dashboard/support",
      icon: IconListManager().supportIcon,
    },
    {
      id: 10,
      title: "Profile",
      path: "/dashboard/settings",
      icon: IconListManager().profileIcon,
    },
    // {
    //   id: 12,
    //   title: "Gift Cards",
    //   path: "/dashboard/giftCards",
    //   icon: IconListManager().GiftCardSmall,
    // },
    {
      id: 11,
      title: "Legal Information",
      path: "/dashboard/legal",
      icon: IconListManager().legalIcon,
    },
  ];

  return SidebarDatas;
}
