/**
 * @Copyright 2023 BookWater Tech Pvt Ltd
 * @author
 * @description Booking.jsx is the parent file for the booking page
 * @date 06/06/23
 * @version 0.0.8 - Initial Release
 */
import * as React from "react";
import Button from "@mui/material/Button";
import Slide from "@mui/material/Slide";
import { useDispatch, useSelector } from "react-redux";
import Dialog from "@mui/material/Dialog";
import Typography from "@mui/material/Typography";
import { Box, DialogContent, TextField } from "@mui/material";
import appColors from "../../../Assets/AppTheme/appColors";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function GiftCardDetailsDialog({ openDialog, setOpenDialog }) {
  const dispatch = useDispatch();
  const [acceptCondition, setAcceptCondition] = React.useState(false);
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [phoneNumberError, setPhoneNumberError] = React.useState(false);
  const [isLogOut, setIsLogOut] = React.useState(false);

  //For closing dialog box
  const handleClose = () => {
    setOpenDialog(false);
    setPhoneNumber("");
  };

  //function for handling phone number function
  const handelPhoneNo = (event) => {
    let reg = /^[6-9][0-9]{9}$/;
    var result = reg.test(event.target.value);
    setPhoneNumberError(!result);
    setPhoneNumber(event.target.value);
  };

  return (
    <div>
      <Dialog
        open={openDialog}
        onClose={handleClose}
        TransitionComponent={Transition}
        sx={{ backgroundColor: appColors.dialogBackGround }}
        aria-labelledby="responsive-dialog-title"
        PaperProps={{
          sx: {
            borderRadius: "20px", // Set border radius for curved effect
          },
        }}
      >
        <DialogContent sx={{ backgroundColor: appColors.walletCard }}>
          <Box
            sx={{
              borderRadius: "1rem 1rem 0 0",
              height: "10rem",
              width: "100%",
              backgroundColor: appColors.lightGreen,
              display: "flex",
              alignItems: "flex-end",
              padding: "1rem",
              position: "relative",
            }}
          >
            <Box>
              <Typography>50 - 50 Water Drops</Typography>
              <Typography>Double Treat</Typography>
            </Box>
            <Box
              sx={{
                position: "absolute",
                top: 30,
                height: "3rem",
                width: "auro",
                background: "#fff",
                right: 0,
                borderRadius: "1rem 0 0 1rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography>50 - 50 Water Drops</Typography>
            </Box>
          </Box>
          <Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>Gift ID : 53664</Typography>
              <Typography>Double Treat</Typography>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography>
                Description : This gift card offers 50 waterdrops for both you
                and you shares person vaild until sep 01 2024,its a double treat
                you can share to any one.
              </Typography>
            </Box>
            <TextField
              size="small"
              type="number"
              label="Phone Number"
              value={phoneNumber}
              error={phoneNumberError}
              variant="filled"
              color="primary"
              focused
              onChange={handelPhoneNo}
              sx={{
                backgroundColor: "white",
                fontSize: "18px",
                borderRadius: "5px",
                width: "100%",
                marginTop: "2%",
              }}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-evenly",
              marginBottom: "1rem",
            }}
          >
            <Button
              varient="contained"
              size="small"
              sx={{
                color: appColors.white,
                bgcolor: appColors.walletCard,
                boxShadow: 5,
              }}
            >
              Add To Cart
            </Button>
            &nbsp;
            <Button
              sx={{
                color: appColors.white,
                bgcolor: appColors.walletCard,
                boxShadow: 5,
              }}
              varient="contained"
              size="small"
              onClick={handleClose}
            >
              Cancel
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}
