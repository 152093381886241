import appColors from "../../../Assets/AppTheme/appColors";

export const styles = {
  buttonFilter4: {
    backgroundColor: appColors.textColorNew,
    color: appColors.white,
    "&:hover": {
      backgroundColor: appColors.lightGreen,
    },
  },
  buttonFilter5: {
    backgroundColor: appColors.textColorNew,
    color: appColors.white,
    "&:hover": {
      backgroundColor: appColors.lightGreen,
    },
  },
  buttonFilter6: {
    backgroundColor: appColors.textColorNew,
    color: appColors.white,
    "&:hover": {
      backgroundColor: appColors.lightGreen,
    },
  },
  buttonFilter7: {
    backgroundColor: appColors.textColorNew,
    color: appColors.white,
    "&:hover": {
      backgroundColor: appColors.lightGreen,
    },
  },
  buttonFilter1: {
    backgroundColor: appColors.textColorNew,
    color: appColors.white,
    "&:hover": {
      backgroundColor: appColors.lightGreen,
    },
  },
  buttonFilter2: {
    backgroundColor: appColors.textColorNew,
    color: appColors.white,
    "&:hover": {
      backgroundColor: appColors.lightGreen,
    },
  },
  buttonFilter3: {
    backgroundColor: appColors.textColorNew,
    color: appColors.white,
    "&:hover": {
      backgroundColor: appColors.lightGreen,
    },
  },
  buttonHeader: {
    fontSize: "1rem",
    color: appColors.Textgray,
    fontWeight: "bold",
    marginBottom: ".5rem",
  },
  ButtonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginBottom: "1rem",
    gap: 1,
  },
  applyFilter: {
    backgroundColor: appColors.textColorNew,
    color: appColors.white,
    fontSize: ".7rem",
    padding: "10px 20px",
    borderRadius: "0", // Set the border radius to 0 (no rounding)
    borderTopRightRadius: "15px",
    borderBottomLeftRadius: "15px",
    height: "30px",
    "&: hover": {
      backgroundColor: "#2FEAC5 ",
    },
  },
};
